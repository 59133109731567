<template>
  <div class="monthly-report">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-date-picker
          v-model="dataForm.month"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select v-model="dataForm.status" placeholder="请选择状态" clearable>
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button type="primary" plain @click="getMonthEmpStudyList"
        >查询</el-button
      >
      <el-button type="success" plain @click="formExport">导出</el-button>
    </el-form>

    <div v-if="formVisible" class="form">
      <ul class="cu-table">
        <li class="cu-tr">
          <span class="cu-td title" style="color: #000">员工学习月度报表</span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow2">培训周期</span>
          <span class="cu-td grow6" style="color: #888">{{
            dataForm.month
          }}</span>
          <span class="cu-td grow2">考核时间</span>
          <span class="cu-td grow4" style="color: #888">{{
            dataForm.month
          }}</span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow4">培训主题</span>
          <span class="cu-td grow6">培训内容</span>
          <span class="cu-td grow2">培训时长</span>
          <span class="cu-td grow2">考试要求</span>
        </li>

        <li
          class="cu-tr"
          v-for="(item, index) in EmpStudy.examEntities"
          :key="index"
        >
          <span class="cu-td grow4" style="color: #888">{{
            item.peixunTitle
          }}</span>
          <span class="cu-td grow6" style="color: #888">{{
            item.peixunContent
          }}</span>
          <span class="cu-td grow2" style="color: #888"
            >{{
              item.countPeixunDate ? item.countPeixunDate / 60 : 0
            }}分钟</span
          >
          <span class="cu-td grow2" style="color: #888"
            >{{ item.examScore }}分</span
          >
        </li>

        <li class="cu-tr">
          <span class="cu-td grow4">学员总数</span>
          <span class="cu-td grow6">完成人数</span>
          <span class="cu-td grow4">完成率</span>
        </li>

        <li class="cu-tr">
          <span class="cu-td grow4" style="color: #888">{{
            EmpStudy.countEmployeeNumber
          }}</span>
          <span class="cu-td grow6" style="color: #888">{{
            EmpStudy.successEmployeeNumber
          }}</span>
          <span class="cu-td grow4" style="color: #888"
            >{{
              (EmpStudy.successEmployeeNumber / EmpStudy.countEmployeeNumber) *
              100
            }}%</span
          >
        </li>

        <li class="cu-tr">
          <span class="cu-td grow1">序号</span>
          <span class="cu-td grow1">姓名</span>
          <span class="cu-td grow1">性别</span>
          <span class="cu-td grow1">工号</span>
          <span class="cu-td grow2">身份证号</span>
          <span class="cu-td grow2">岗位</span>
          <span class="cu-td grow1">学习学时</span>
          <span class="cu-td grow1">学习结果</span>
          <span class="cu-td grow1">考核分数</span>
          <span class="cu-td grow1">考试结果</span>
          <span class="cu-td grow1">签名</span>
          <span class="cu-td grow1">照片</span>
        </li>

        <li
          class="cu-tr"
          v-for="(item, index) in EmpStudy.employees"
          :key="'emp' + index"
        >
          <span class="cu-td grow1">{{ index + 1 }}</span>
          <span class="cu-td grow1">{{ item.name }}</span>
          <span class="cu-td grow1">{{ item.sex }}</span>
          <span class="cu-td grow1">{{ item.number }}</span>
          <span class="cu-td grow2">{{ item.shenfenzheng }}</span>
          <span class="cu-td grow2">{{ item.gangwei }}</span>
          <span class="cu-td grow1"
            >{{ parseInt(item.studyCount / 60) }}分</span
          >
          <span class="cu-td grow1">{{ item.studyResult }}</span>
          <span class="cu-td grow1">{{ item.examScore }}分</span>
          <span class="cu-td grow1">{{ item.examResult }}</span>
          <span class="cu-td grow1">
            <el-image
              v-if="item.signPic"
              fit="contain"
              class="sign-img"
              :src="$http.common.pic + item.signPic"
              :preview-src-list="[$http.common.pic + item.signPic]"
          /></span>
          <span class="cu-td grow1"
            ><el-image
              v-if="item.headerPic"
              fit="contain"
              class="header-img"
              :src="$http.common.pic + item.headerPic"
              :preview-src-list="[$http.common.pic + item.headerPic]"
          /></span>
        </li>
      </ul>
    </div>

    <el-empty v-if="empty" :description="empty_text" />
  </div>
</template>

<script>
export default {
  name: "monthlyRp",

  data() {
    return {
      dataForm: {
        month: "",
        status: "",
      },
      statusList: [
        {
          id: 0,
          name: "未学完",
        },
        {
          id: 1,
          name: "已学完",
        },
      ],
      EmpStudy: {}, // 月度报表
      empty: true,
      empty_text: "请先选择月份",
      formVisible: false,
    };
  },

  created() {},

  methods: {
    // 重新后去获取数据
    refreshDataList() {},

    // 获取月度报表
    getMonthEmpStudyList() {
      if (!this.dataForm.month) return this.$message.warning("请先选择日期");

      this.$http.employee
        .lookMonthEmployeeStudyList(this.dataForm)
        .then((res) => {
          (this.formVisible = false), (this.empty = true);
          this.empty_text = "暂无数据";
          if (res.code != 0) return;

          (this.formVisible = true), (this.empty = false);
          this.EmpStudy = res.data;
        });
    },

    // 导出报表
    formExport() {
      this.$message.info("紧急开发中");
      return;
      if (!this.dataForm.month) return this.$message.warning("请先选择日期");

      let fileName =
        this.userdata.company.name +
        "-" +
        this.dataForm.month +
        "-" +
        "汇总表.xls";
      this.$http.employee.exportMonthEmployeeStudyList(this.dataForm, fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
.monthly-report {
  padding: 20px;
}
.form {
  font-weight: 550;
  color: #606266;
  font-size: 14px;

  .cu-table {
    border-top: 1px solid;
    border-left: 1px solid;

    border-color: #ddd;

    .pic {
      width: 100%;
      margin: auto;
    }
  }
  .cu-tr {
    display: flex;
  }
  .td-color {
    color: 999 !important;
  }
  .cu-td {
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #ddd;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 17px;
    color: #909399;
    padding: 10px;
  }

  .user-info {
    position: relative;

    .user-img {
      position: absolute;
      width: calc(100% / 7);
      height: 100%;
      top: 0;
      right: 0;
      border-right: 1px solid;
      border-bottom: 1px solid;
      box-sizing: border-box;
      box-sizing: border-box;
      background-color: #fff;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-img,
  .sign-img {
    width: 100%;
    height: 100px;
  }

  .grow1 {
    width: calc(100% / 14) !important;
  }
  .grow2 {
    width: calc(100% / 14 * 2) !important;
  }
  .grow3 {
    width: calc(100% / 14 * 3) !important;
  }
  .grow4 {
    width: calc(100% / 14 * 4) !important;
  }
  .grow5 {
    width: calc(100% / 14 * 5) !important;
  }
  .grow6 {
    width: calc(100% / 14 * 6) !important;
  }

  .grow7 {
    width: calc(100% / 14 * 7) !important;
  }
  .grow8 {
    width: calc(100% / 14 * 8) !important;
  }
  .grow9 {
    width: calc(100% / 14 * 9) !important;
  }
  .grow10 {
    width: calc(100% / 14 * 10) !important;
  }
  .grow11 {
    width: calc(100% / 14 * 11) !important;
  }
  .grow12 {
    width: calc(100% / 14 * 12) !important;
  }
}
</style>
